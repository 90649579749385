import { useEffect, useMemo, useState } from 'react'
import {
	QueryObserverOptions,
	useInfiniteQuery,
	useQueries,
	useQuery,
} from 'react-query'
import {
	filter,
	flatMap,
	isDeepEqual,
	isNot,
	isTruthy,
	map,
	pick,
	pipe,
	prop,
	sortBy,
} from 'remeda'
import { debounceTime, Subject } from 'rxjs'

import { optionIds } from '../../constants'
import { prepareTaskForHash } from '../../data-hash/utils'
import { getHash } from '../../sync'
import { Attachment, Task } from '../../types'
import { fileKeys } from '../files/file-keys'
import {
	createFetchProjectListQueryFn,
	createTaskDetailQuery,
	taskKeys,
} from '../queries'
import { recentsKeys } from '../recents/recentsKeys'
import useStore from '../useStore'
import { usePlayerId, usePlayerOptions } from './player'
import { WatchableOptions } from './types'

type UseProjectListIdsProps = {
	filterFn?: (task: Task) => boolean
	inactiveFrom?: 'none' | 'today' | 'yesterday' | 'week' | 'month' | 'year'
}

const ONE_HOUR = 1000 * 60 * 60
const ONE_WORK_DAY = 1000 * 60 * 60 * 8

const fileQueryConfig = {
	cacheTime: ONE_WORK_DAY,
	staleTime: ONE_HOUR,
}

const sortByTitle = sortBy((task: Task) => task.title)

export const useProjectListIds = (
	{
		filterFn = () => true,
		inactiveFrom = 'none',
	}: UseProjectListIdsProps = {},
	config = {}
) => {
	const { apiAdapter, queryClient } = useStore.getState()
	const playerId = usePlayerId()
	const { data: optionsData = {} } = usePlayerOptions([
		optionIds.STARRED_PROJECTS,
	])

	const { [optionIds.STARRED_PROJECTS]: starredProjects = [] } = optionsData

	const queryKey = useMemo(
		() =>
			taskKeys.list({
				isProject: true,
				inactiveFrom,
				statusCodes: inactiveFrom === 'none' ? 'active' : 'all',
			}),
		[inactiveFrom]
	)
	const { data, ...other } = useInfiniteQuery(
		queryKey,
		createFetchProjectListQueryFn({ apiAdapter, queryClient }),
		{
			getNextPageParam: (lastPage) =>
				lastPage?.hasMore ? lastPage.page + 1 : undefined,
			getPreviousPageParam: (firstPage) =>
				!firstPage || firstPage?.page === 1
					? undefined
					: firstPage.page - 1,
			...config,
		}
	)
	const projectBelongsToPlayer = (project: Task) =>
		project.ownerId === playerId

	const filteredProjects = useMemo(() => {
		if (data && Array.isArray(data.pages)) {
			return pipe(
				data.pages,
				filter(isTruthy),
				flatMap(prop('items')),
				map((task) =>
					starredProjects.includes(task.id)
						? { ...task, isStarred: true }
						: task
				),
				filter(filterFn)
			)
		} else {
			return []
		}
	}, [data, filterFn, starredProjects])
	const ownProjectIds: string[] = pipe(
		filteredProjects,
		filter(projectBelongsToPlayer),
		sortByTitle,
		map(prop('id'))
	)
	const sharedProjectIds: string[] = pipe(
		filteredProjects,
		filter(isNot(projectBelongsToPlayer)),
		sortByTitle,
		map(prop('id'))
	)
	const starredProjectIds: string[] = pipe(
		filteredProjects,
		filter(prop('isStarred')),
		sortByTitle,
		map(prop('id'))
	)
	const sections = {
		ownProjectIds,
		sharedProjectIds,
		starredProjectIds,
	}

	return {
		data: data
			? { projects: filteredProjects.map(prop('id')), sections }
			: undefined,
		...other,
	}
}

export const useRecentProjectListIds = (params?: { limit: number }) => {
	const { apiAdapter } = useStore.getState()
	return useQuery<string[]>(recentsKeys.viewedProjects(), async () =>
		apiAdapter.player.getRecentlyViewedProjects(params)
	)
}

export const useTask = (
	taskId: string,
	{ watchFields, ...options }: WatchableOptions<Task> = {}
) => {
	const { apiAdapter, queryClient } = useStore.getState()
	const [hash, setHash] = useState<string>()

	const queryKey = taskKeys.detail(taskId)
	const taskDetailQuery = createTaskDetailQuery(apiAdapter, queryClient)
	const params = hash ? { h: hash } : undefined

	useEffect(() => {
		if (!taskId) {
			return
		}

		const queryCache = queryClient.getQueryCache()
		return queryCache.subscribe((event) => {
			if (!event || !isDeepEqual(event.query.queryKey, queryKey)) {
				return
			}

			switch (event.type) {
				case 'queryUpdated': {
					const currentTask = queryClient.getQueryData<
						Task & { hash?: string }
					>(taskKeys.detail(taskId))

					if (!currentTask) {
						return
					}

					if (currentTask.hash) {
						setHash(currentTask.hash)
					} else {
						setHash(getHash(prepareTaskForHash(currentTask)))
					}
					break
				}
			}
		})
	}, [queryClient, queryKey, taskId])

	return useQuery({
		...taskDetailQuery(taskId, params),
		select: watchFields
			? (data) => data && pick(data, watchFields)
			: undefined,
		...options,
	})
}

export const useTasks = (taskIds: string[], options = {}) => {
	const apiAdapter = useStore.getState().apiAdapter
	const queryClient = useStore.getState().queryClient

	const taskDetailQuery = createTaskDetailQuery(apiAdapter, queryClient)

	return useQueries(
		taskIds.map((taskId) => ({
			...taskDetailQuery(taskId),
			...options,
			enabled: taskIds.length > 0,
		}))
	)
}

export const sortTaskFiles = sortBy<Attachment>([prop('lastUpdated'), 'asc'])
export const useFiles = (
	taskId: string,
	config?: Pick<QueryObserverOptions, 'staleTime'>
) =>
	useQuery(
		taskKeys.filesList(taskId),
		async ({ queryKey, signal }) => {
			const taskId = queryKey[2]
			if (!taskId) return []

			const { apiAdapter, queryClient } = useStore.getState()
			const response = await apiAdapter.tasks.getFiles(taskId, { signal })

			const files = response.filter(isTruthy)
			files.forEach((file) =>
				queryClient.setQueryData(fileKeys.detail(file.id), file)
			)
			return files
		},
		{
			select: (files) => sortTaskFiles(files.filter(isTruthy)),
			...fileQueryConfig,
			...config,
		}
	)

const debounceSubject$ = new Subject()
const debounce$ = debounceSubject$.pipe(debounceTime(250))

export const useSearchResults = (searchQuery: any, options = {}) => {
	const [query, setQuery] = useState(searchQuery)
	const { apiAdapter } = useStore.getState()

	useEffect(() => {
		;(debounce$ as any).next(searchQuery)
	}, [searchQuery])

	useEffect(() => {
		const subscription = debounce$.subscribe((query) => {
			setQuery(query)
		})
		return () => subscription.unsubscribe()
	}, [])

	return useInfiniteQuery(
		[
			'tasks',
			{
				search: query,
				statusCodes: 'all',
				orderBy: '-isActive,-dateCreated',
				pageSize: 15,
			},
		],
		async ({ queryKey, pageParam = 1, signal }) => {
			const promise = apiAdapter.tasks.getList(
				{
					...(typeof queryKey[1] === 'object' ? queryKey[1] : {}),
					page: pageParam,
				},
				{ signal }
			)

			const result = await promise
			return result
		},
		{
			enabled: Boolean(query),
			getNextPageParam: (lastPage) =>
				lastPage?.hasMore ? lastPage.page + 1 : undefined,
			getPreviousPageParam: (firstPage) =>
				firstPage?.page === 1 ? undefined : firstPage.page - 1,
			...options,
		}
	)
}
