import { z } from 'zod'

export const TaskTimeEntrySchema = z.object({
	dateCreated: z.string().datetime(),
	description: z.string().nullable(),
	id: z.string().uuid(),
	isEdited: z.boolean().default(false),
	secondsTaken: z.number().min(0),
	startDate: z.string().datetime().nullable(),
	stopDate: z.string().datetime().nullable(),
	taskId: z.string(),
	userId: z.string(),
})

export type TaskTimeEntry = z.infer<typeof TaskTimeEntrySchema>
