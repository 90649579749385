import { createAsyncStoragePersistor } from 'react-query/createAsyncStoragePersistor-experimental'
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'

import {
	asyncStorage,
	createQueryClient,
	setStoragePersistor,
	useStore,
} from '@tyto/dna/store'

import { createDebugFn } from './debug/debug-utils'

const cacheTime = 1000 * 60 * 60 * 24

export const configureQueryClient = () => {
	const queryClient = createQueryClient({ cacheTime })
	const asyncStoragePersistor = createAsyncStoragePersistor({
		storage: asyncStorage,
	})
	persistQueryClient({
		buster: window.version || '',
		queryClient,
		persistor: asyncStoragePersistor,
		maxAge: cacheTime,
	})

	setStoragePersistor(asyncStoragePersistor)
	useStore.setState({ queryClient })

	createDebugFn('clearQueryCache', () => {
		queryClient.clear()
	})
}
