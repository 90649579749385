import React from 'react'
import * as Sentry from '@sentry/react'
// import * as Spotlight from '@spotlightjs/spotlight'
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom'

import { isApiError, isApiErrorNoResponse } from '@tyto/dna'
import { isStoreError, unwrapStoreException } from '@tyto/dna/store'
import { env } from '@tyto/utils'

const nodeEnv = env('NODE_ENV')
// Defaulting to NODE_ENV because I'm not sure if Sentry handles undefined
// values. Would be worth checking the docs to make sure.
const appEnv = env('APP_ENV', nodeEnv)

export const configureSentry = () => {
	console.debug('Sentry.init()', {
		release: window.version,
		environment: appEnv,
		nodeEnv: nodeEnv,
	})

	// if (nodeEnv !== 'production') {
	// 	return
	// }

	Sentry.init({
		dsn: 'https://943bdf462959487bb8f232b6f8c3e809@bugs.tyto.me/2',
		environment: appEnv,
		release: window.version,
		normalizeDepth: 10, // Depth of state context
		integrations: [
			Sentry.browserProfilingIntegration(),
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect: React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		ignoreErrors: [
			'ResizeObserver loop limit exceeded',
			'ResizeObserver loop completed with undelivered notifications.',
		],
		// Session Replay
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		tracesSampleRate: 1.0,
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		//tracePropagationTargets: [
		//	'localhost',
		//	/^https:\/\/api.tyto.me/,
		//	/^https:\/\/alpha-api.tyto.me/,
		//	/^https:\/\/beta-api.tyto.me/,
		//],
		// Set profilesSampleRate to 1.0 to profile every transaction.
		// Since profilesSampleRate is relative to tracesSampleRate,
		// the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
		// For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
		// results in 25% of transactions being profiled (0.5*0.5=0.25)
		profilesSampleRate: 1.0,
		beforeSend: (event, hint) => {
			// ResizeObserver errors can be ignored as explained here:
			// https://github.com/DevExpress/testcafe/issues/4857#issuecomment-598775956
			// discarding these types of events so it doesn't add to our Sentry quota.
			const error = hint.originalException

			const message = error instanceof Error ? error.message : error

			if (nodeEnv !== 'production') {
				console.error('captured by sentry:', error)
				// return null
			}

			if (
				message ===
					'ResizeObserver loop completed with undelivered notifications.' ||
				message === 'ResizeObserver loop limit exceeded'
			) {
				// Discard event
				return null
			}

			if (event.extra) {
				event.extra['originalException'] = error
			} else {
				event.extra = { originalException: error }
			}

			return event
		},
	})

	if (env('NODE_ENV') === 'development') {
		// Spotlight.init()
	}
}

export const handleError = (error: Error) => {
	if (nodeEnv !== 'production') {
		console.error(error)
	}
	Sentry.withScope((scope) => {
		let exception: unknown
		if (isStoreError(error)) {
			exception = unwrapStoreException(error)

			// For NO_RESPONSE errors, set the severity lower because we can't
			// really do much about it.
			if (isApiError(error) && isApiErrorNoResponse(error)) {
				scope.setTag('api-error', error.type)
				scope.setLevel('info')
			}
		} else {
			exception = error
		}

		Sentry.captureException(exception)
	})
}
