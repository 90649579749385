import { QueryClient } from 'react-query'
import { indexBy, isTruthy, pick, prop } from 'remeda'

import { TaskActivity } from '../../task-activity/task-activity-schemas'
import {
	DataSource,
	TaskActivityTaskMeta,
	TaskActivityUserMeta,
	TaskActivityWorkflowMeta,
} from '../../task-activity/task-activity-utils'
import { getIdsForTaskLogs } from '../../task-activity/taskLogs'
import { ApiAdapter } from '../api'
import { fetchTask, fetchUser } from '../queries'
import { taskKeys } from '../queries/tasks/taskKeys'
import { userKeys } from '../queries/users/userKeys'
import { fetchWorkflows, workflowKeys } from '../queries/workflows'

export const getDataSourceTaskActivityMeta = async (
	{
		apiAdapter,
		queryClient,
	}: { apiAdapter: ApiAdapter; queryClient: QueryClient },
	activity: TaskActivity[]
): Promise<DataSource> => {
	const { tasks, users, workflows } = getIdsForTaskLogs(activity)
	let taskResults: TaskActivityTaskMeta[] = []

	if (tasks.length > 0) {
		const newTasks = await Promise.all(
			tasks.map(async (taskId): Promise<TaskActivityTaskMeta | null> => {
				const task = await queryClient.fetchQuery({
					queryKey: taskKeys.detail(taskId),
					queryFn: fetchTask(apiAdapter, queryClient, taskId),
				})
				return task ? pick(task, ['id', 'title']) : null
			})
		)
		taskResults = newTasks.filter(isTruthy)
	}

	let userResults: TaskActivityUserMeta[] = []
	if (users.length > 0) {
		const newUsers = await Promise.all(
			users.map(async (userId): Promise<TaskActivityUserMeta | null> => {
				const user = await queryClient.fetchQuery({
					queryKey: userKeys.detail(userId),
					queryFn: fetchUser(apiAdapter, userId),
				})
				return user
					? pick(user, ['id', 'name', 'nickname', 'gravatar'])
					: null
			})
		)
		userResults = newUsers.filter(isTruthy)
	}

	const workflowsById = await queryClient.fetchQuery({
		queryKey: workflowKeys.list(),
		queryFn: fetchWorkflows(apiAdapter),
	})
	let workflowResults: TaskActivityWorkflowMeta[] = []
	if (workflows.length > 0) {
		workflowResults = workflows
			.map((workflowId): TaskActivityWorkflowMeta | null => {
				const workflow = workflowsById[workflowId]
				return workflow ? pick(workflow, ['id', 'title']) : null
			})
			.filter(isTruthy)
	}

	return {
		task: indexBy(taskResults, prop('id')),
		user: indexBy(userResults, prop('id')),
		workflow: indexBy(workflowResults, prop('id')),
	}
}
