import { formatISO, parseISO } from 'date-fns'

import { FileData } from '@tyto/task-activity'

import {
	Attachment,
	FileOrMobileFile,
	FileWithId,
	MobileFile,
} from '../../types'

export const isMobileFile = (file: object): file is MobileFile => 'uri' in file

// File types
// Mobile: { name: string, type: string, uri: string }
// Browser: { name: string, type: string, size: number, lastModified: number, lastModifiedDate: Date, webkitRelativePath: string }
// 		- URL.createObjectURL(file) --> returns blob:http://localhost:3001/8fd88bc1-084a-446f-aafa-3ee600f12837
// 		  https://stackoverflow.com/questions/49209756/do-i-always-need-to-call-url-revokeobjecturl-explicitly/49346614#49346614
// 		  URL.revokeObjectURL(objectUrl) --> releases the memory of this blob when you're done with it
//		- const reader = new FileReader()
//		  reader.onload = (event) => event.target.result
//		  reader.readAsDataURL(file) --> returns base64 encoded string
// API:
// 	Task: { taskId: string, id: string, filename: string, contentType: string, totalBytes: number, lastUpdated: string }
// 	Message: { chatMessageId: string, id: string, filename: string, contentType: string, totalBytes: number, lastUpdated: string }

type ConvertFileDataType =
	| { type: 'chat'; value: string }
	| { type: 'task'; value: string }
export const convertAttachmentToFileData = (
	file: Attachment,
	{ type, value }: ConvertFileDataType
): FileData => {
	const newFile = {
		id: file.id,
		filename: file.name,
		contentType: file.contentType,
		lastUpdated: file.lastUpdated,
		totalBytes: file.size,
	}

	if (type === 'chat') {
		return {
			...newFile,
			chatMessageId: value,
			type: 'chat',
		}
	} else {
		return {
			...newFile,
			taskId: value,
			type: 'task',
		}
	}
}

// TODO: Might have to move this to a different file so that there aren't errors on mobile.
export const convertBrowserFileToAttachment = (
	file: File | FileWithId,
	type: 'chat' | 'task'
): Attachment => {
	const url = URL.createObjectURL(file)
	return {
		id: file.id || url?.replace(/.+?([\w-]+)$/, '$1'),
		lastUpdated: parseISO(formatISO(file.lastModified)),
		name: file.name,
		size: file.size,
		contentType: file.type,
		type,
		url,
		file,
	}
}

export const convertFileToAttachment = (
	file: FileOrMobileFile,
	type: 'chat' | 'task'
) => {
	if (isMobileFile(file)) {
		return file
	} else {
		return convertBrowserFileToAttachment(file, type)
	}
}

export const getMessageFileUrl = (
	apiUrl: string,
	messageId: string,
	fileId: string,
	token: string,
	thumbnail = false
) =>
	`${apiUrl}/chat/messages/${messageId}/files/${fileId}?auth_token=${token}${
		thumbnail ? '&thumbnail=true' : ''
	}`

export const getTaskFileUrl = (
	apiUrl: string,
	taskId: string,
	fileId: string,
	token: string,
	thumbnail = false
) =>
	`${apiUrl}/tasks/${taskId}/files/${fileId}?auth_token=${token}${
		thumbnail ? '&thumbnail=true' : ''
	}`

export const getTaskIdFromAttachment = (attachment: Attachment) => {
	const taskId = attachment?.url?.replace(
		/^.+?tasks\/([\w-]+)\/files\/.+$/,
		'$1'
	)
	return taskId
}
