import { InfiniteData, QueryFunction } from 'react-query'
import { uniqBy } from 'remeda'

import { StoreContext, Task } from '../../../types'
import { ApiTaskListResult, TaskListParams } from '../../api'
import updateTaskQueryCacheWithList from '../../utils/updateTaskQueryCacheWithList'
import { getQueryLastUpdated, setQueryLastUpdated } from '../utils'

const mergeLastUpdatedTaskListResults = <T extends ApiTaskListResult<Task>>(
	listA: T,
	listB: T
): T => {
	const items = uniqBy([...listA.items, ...listB.items], (item) => item.id)

	return {
		...listA,
		count: items.length,
		items,
	}
}

const flattenInfiniteListResult = <TData>(
	listResult:
		| ApiTaskListResult<TData>
		| InfiniteData<ApiTaskListResult<TData>>
): ApiTaskListResult<TData> => {
	if ('pages' in listResult) {
		const items = listResult.pages.flatMap((page) => page?.items || [])
		return {
			items,
			count: items.length,
			page: 1,
			pageSize: items.length,
			hasMore: false,
			privateTaskCount: 0,
		}
	}
	return listResult
}

export const createFetchProjectListQueryFn =
	({
		apiAdapter,
		queryClient,
	}: Pick<StoreContext, 'apiAdapter' | 'queryClient'>): QueryFunction<
		ApiTaskListResult<Task> | undefined
	> =>
	async ({ queryKey, signal }) => {
		const params: TaskListParams = queryKey[2] || {}
		const prevResult = queryClient.getQueryData<
			ApiTaskListResult<Task> | InfiniteData<ApiTaskListResult<Task>>
		>(queryKey)

		const paramsWithMeta = Object.assign({}, params, { isProject: true })

		const lastUpdated = await getQueryLastUpdated(queryKey)
		if (lastUpdated && prevResult) {
			paramsWithMeta.updatedAfter = lastUpdated
		}

		const result = await apiAdapter.tasks.getList(paramsWithMeta, {
			signal,
		})
		if (result) {
			let mergedResult = result
			// Merge with previous data
			if (lastUpdated && prevResult) {
				mergedResult = mergeLastUpdatedTaskListResults(
					flattenInfiniteListResult(prevResult),
					result
				)
			}

			updateTaskQueryCacheWithList(queryClient, result.items)
			setQueryLastUpdated(queryKey)

			return mergedResult
		} else {
			return queryClient.getQueryData(queryKey)
		}
	}
