import { isAxiosError } from 'axios'
import produce from 'immer'
import { jwtDecode } from 'jwt-decode'
import { concat, take, uniq } from 'ramda'
import { switchMap } from 'rxjs'
import { Socket } from 'socket.io-client'
import { z } from 'zod'
import { StoreApi } from 'zustand'

import { noop } from '@tyto/utils'

import { isApiError, isApiErrorStatusCode } from '../api-adapter/error-handler'
import { events } from '../constants'
import {
	createSocketObservable,
	getSocketClient,
	getSocketUrl,
} from '../socketUtils'
import { PlayerOptions, Slice, TeardownFn, User } from '../types'
import { LoginResponse } from './api'
import { clearStoragePersistor } from './createQueryClient'
import { updatePlayerOptionsMutation } from './mutations/player'
import { updateTaskSubject } from './observables'
import { getDefaultPlayerOptions } from './player/getDefaultPlayerOptions'
import {
	createQueryObservable,
	fetchPlayerOptions,
	fetchTask,
	fetchUser,
	fetchWorkflows,
	playerKeys,
	taskKeys,
	userKeys,
	workflowKeys,
} from './queries'
import { setupGeneralSocketListeners } from './setupGeneralSocketListeners'
import { AppState, MutatedAppState, MutatedSetState } from './store-types'
import {
	getAddedTags,
	getRecentTagsFromQueryCache,
	updateTagsMutation,
} from './tags'
import { getServiceFromState, userOptionIds } from './utils'
import parseQueryClientError from './utils/parseQueryClientError'

// This is to fix the type error when trying to assign a non-mutable socket to
// the draft.socket field which is inherently mutable. We don't plan to mutate
// it so it's safe to cast it to something compatible.
interface MutableSocketDraft extends Socket {
	receiveBuffer: any[][]
}

export interface PlayerSlice extends Slice {
	// TODO: player options should go here. They don't work well when
	// lazy-loaded.
	id: string | null
	isLoading: boolean
	authToken: string | null
	inboxTaskId: string | null
	options: PlayerOptions
	getRecentTags: () => string[]
	login: (username: string, password: string) => Promise<LoginResponse | null>
	logout: () => void
	provideToken: (token: string) => Promise<User | null>
	updateOptions: (changes: Partial<PlayerOptions>) => void
}

const localStorage =
	typeof global.window !== 'undefined' ? global.window.localStorage : null

export const createBillingPlanUpdateSocket = (socket: Socket) =>
	createSocketObservable<{ planId: string }>(
		socket,
		events.BILLING_PLAN_UPDATE
	)

const handlePostLogin = async (
	token: string,
	set: MutatedSetState,
	get: StoreApi<AppState>['getState']
) => {
	const { apiAdapter, queryClient, socket } = get()
	// TODO: remove this when we return the player from the login response
	const player: User | null = await apiAdapter.users
		.getDetail('me')
		.catch((err) => {
			if (
				isAxiosError(err) &&
				err.status &&
				err.status >= 400 &&
				err.status <= 499
			) {
				set((draft) => {
					draft.player.authToken = null
				})
				return null
			}

			return Promise.reject(err)
		})

	// Fetch player theme
	await queryClient.fetchQuery(
		playerKeys.options(),
		fetchPlayerOptions(apiAdapter, { optionIds: userOptionIds })
	)

	// Pre-cache with the user id aswell
	if (player) {
		await queryClient.invalidateQueries(userKeys.detail('me'))
		queryClient.setQueryData(userKeys.detail('me'), player)
		queryClient.setQueryData(userKeys.detail(player.id), player)

		set((draft) => {
			draft.player.id = player.id
			draft.player.isLoading = false
		})
	} else {
		set((draft) => {
			draft.player.isLoading = false
		})
	}

	// Update socket with new token
	if (socket) {
		// @ts-expect-error - private property (https://github.com/socketio/socket.io/discussions/4246#discussioncomment-1952661)
		socket.io.uri = getSocketUrl(token)

		// Force reconnection
		socket.disconnect().connect()
	} else {
		set((draft) => {
			draft.socket = getSocketClient(token) as MutableSocketDraft
		})
	}

	return player
}

const AuthPayloadSchema = z.object({
	email: z.string(),
	exp: z.number(),
	iat: z.number(),
	id: z.string(),
	organisationId: z.string(),
})
type AuthPayload = z.infer<typeof AuthPayloadSchema>

export const getTokenData = (token: string) => {
	const decoded = jwtDecode<AuthPayload>(token)
	return AuthPayloadSchema.parse(decoded)
}

const createPlayerSlice: MutatedAppState<PlayerSlice> = (set, get, api) => ({
	id: null,
	isLoading: false,
	authToken: null,
	inboxTaskId: null,
	options: getDefaultPlayerOptions(),
	init: () => {
		const apiAdapter = get().apiAdapter
		const queryClient = get().queryClient
		const socket$ = getServiceFromState(api, 'socket')

		const errors$ = get().errors

		// Load the player into the store
		// Also caches player in react-query
		// Continue to listen for changes to the player cache
		// Keep store synced with cache
		const player$ = createQueryObservable(queryClient, {
			queryKey: userKeys.detail('me'),
			queryFn: fetchUser(apiAdapter, 'me'),
		})
		const playerSubscription = player$.subscribe((playerResult) => {
			const { isLoading } = playerResult

			set((draft) => {
				draft.player.isLoading = isLoading
			})
		})

		let generalSocketsListenerUnsubscribe: TeardownFn = noop
		const generalSocketsSubscription = socket$.subscribe(() => {
			// TODO: handle unsubscribing socket listeners a bit better than this
			generalSocketsListenerUnsubscribe()
			generalSocketsListenerUnsubscribe =
				setupGeneralSocketListeners(get())
		})

		const billingPlanUpdateSubscription = socket$
			.pipe(switchMap((socket) => createBillingPlanUpdateSocket(socket)))
			.subscribe(({ planId }) => {
				queryClient.setQueryData(
					userKeys.organisation(),
					produce((draft) => {
						draft.planId = planId
					})
				)
			})

		// Load inbox task early so it's available for adding tasks
		// Also caches inbox task in react-query
		// Continues to listen for changes to the inbox task cache
		// No unsubscribe is being used here
		createQueryObservable(queryClient, {
			queryKey: taskKeys.detail('inbox'),
			queryFn: fetchTask(apiAdapter, queryClient, 'inbox'),
		}).subscribe((result) => {
			const { data: inboxTask, error } = result
			if (error) {
				errors$.next(
					parseQueryClientError(
						error,
						`Couldn't fetch player's inbox task`
					)
				)
			} else {
				set((draft) => {
					draft.player.inboxTaskId = inboxTask ? inboxTask.id : null
				})
			}
		})

		// Load workflows early so it's available for adding tasks
		// Also caches workflows in react-query
		// Continues to listen for changes to the workflows cache
		// No unsubscribe is being used here
		createQueryObservable(queryClient, {
			queryKey: workflowKeys.list(),
			queryFn: fetchWorkflows(apiAdapter),
		}).subscribe((result) => {
			const { error } = result
			if (error) {
				errors$.next(
					parseQueryClientError(error, `Couldn't fetch workflow list`)
				)
			}
		})

		// Prefeches player options so they're saved to react-query cache
		queryClient.prefetchQuery(
			playerKeys.options(),
			fetchPlayerOptions(apiAdapter, { optionIds: userOptionIds })
		)

		// Listen for task updates and update recent tags
		// Checks for recent tags in the cache
		// Recents tags could be stale or not in cache
		// Could be streamed with an observable?
		// Updates player options recent tags everytime a task is updated
		updateTaskSubject.subscribe(({ changes, oldTask }) => {
			const addedTags = getAddedTags(oldTask, changes)

			if (addedTags.length > 0) {
				const oldTags = getRecentTagsFromQueryCache(queryClient)
				const newTags = take(10, uniq(concat(addedTags, oldTags)))
				updateTagsMutation(get(), newTags)
			}
		})

		return () => {
			generalSocketsSubscription.unsubscribe()
			playerSubscription.unsubscribe()
			billingPlanUpdateSubscription.unsubscribe()

			generalSocketsListenerUnsubscribe()
		}
	},
	getRecentTags: () => {
		const queryClient = get().queryClient
		return getRecentTagsFromQueryCache(queryClient)
	},
	login: async (username, password) => {
		set((draft) => {
			draft.player.isLoading = true
		})

		const response = await get().apiAdapter.auth.login(username, password)

		if (response === null) {
			set((draft) => {
				draft.player.isLoading = false
			})
			return null
		}

		if (!response || !response.token) {
			return null
		}
		const token = response.token

		set((draft) => {
			draft.player.authToken = token

			if (token) {
				const playerId = draft.player.id
				const data = getTokenData(token)
				if (data?.id && !playerId) {
					draft.player.id = data.id
				}
			}
		})

		const player = await handlePostLogin(token, set, get)

		return { token, user: player } as LoginResponse
	},
	logout: () => {
		// TODO: add logout logic
		// TODO: clear out redux store
		// TODO: clear out zustand store
		get().queryClient.cancelQueries()
		get().queryClient.cancelMutations()
		get().queryClient.clear()
		get().socket?.disconnect()

		clearStoragePersistor()

		set((draft) => {
			draft.player.authToken = null
			draft.socket = null
		})

		localStorage?.removeItem('ty.quoteSwitcher.theme')
		localStorage?.removeItem('ty.quoteSwitcher.darkTheme')
	},
	provideToken: async (token) => {
		set((draft) => {
			draft.player.authToken = token
			draft.player.isLoading = true

			if (token) {
				const playerId = draft.player.id
				const data = getTokenData(token)
				if (data?.id && !playerId) {
					draft.player.id = data.id
				}
			}
		})

		try {
			const player = await handlePostLogin(token, set, get)
			return player
		} catch (err) {
			if (
				isApiError(err) &&
				isApiErrorStatusCode(err) &&
				err.type === 401
			) {
				set((draft) => {
					draft.player.authToken = null
					draft.player.isLoading = true
				})
			}
			throw err
		}
	},
	updateOptions: (changes: Partial<PlayerOptions>) => {
		updatePlayerOptionsMutation(get(), changes)
	},
})

export default createPlayerSlice
