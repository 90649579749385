import createDebug from 'debug'
import produce, { original } from 'immer'
import { WritableDraft } from 'immer/dist/types/types-external'
import mutableMerge from 'lodash/mergeWith'
import { find, has, map, mergeDeepLeft, values } from 'ramda'
import { isCancelledError, QueryClient } from 'react-query'
import { equals, filter, pipe } from 'remeda'
import { Socket } from 'socket.io-client'
import { GetState } from 'zustand'

import { events } from '../../constants'
import { AndCriteria } from '../../filters'
import { TraceableError } from '../../helpers/error-handling'
import {
	addTaskToSection,
	buildSectionData,
	getSectionId,
	removeTaskFromSection,
} from '../../helpers/sections'
import { createSocketObservable } from '../../socketUtils'
import { Section, SectionId, Task } from '../../types'
import { SectionsResult } from '../api'
import { fetchTask, sectionKeys, taskKeys } from '../queries'
import { AppState, MutatedSetState } from '../store-types'
import { mapExistingTasks } from './mapExistingTasks'

const debug = createDebug('dna:userViewSectionsState')

export type SectionsById = {
	[S in SectionId]: Section
}

export const createSectionUpdateSocket = (socket: Socket) =>
	createSocketObservable<{
		section: Pick<Section, 'id' | 'manualSortOrder' | 'sortType' | 'title'>
		userId: string
	}>(socket, events.SECTION_UPDATE)

export const createAddTaskToSections =
	(set: MutatedSetState, get: GetState<AppState>) => (task: Task) => {
		set((draft) => {
			const playerId = draft.player.id
			const userId = draft.userView.user.id
			if (!userId || !playerId) {
				return
			}

			const sectionId = getSectionId(task, userId, playerId)
			const section = draft.userView.sections.byId[sectionId]
			draft.userView.sections.byId[sectionId] = addTaskToSection(
				section,
				task
			)
		})

		setTimeout(() => {
			const userId = get().userView.user.id
			const sectionsQueryKey = sectionKeys.list(userId)
			const sections =
				get().queryClient.getQueryData<SectionsResult>(sectionsQueryKey)

			debug('addTaskToSections', { task, sections })

			if (sections && task.assigneeId === userId) {
				get().queryClient.setQueryData(sectionsQueryKey, sections)
			}
		})
	}

export const createRemoveTaskFromSections =
	(set: MutatedSetState, get: GetState<AppState>) => (taskId: string) => {
		set((draft) => {
			draft.userView.sections.byId = map(
				(section) => removeTaskFromSection(section, taskId),
				draft.userView.sections.byId
			)
		})

		const userId = get().userView.user.id
		if (!userId) {
			return
		}

		const sectionData = get().queryClient.getQueryData<SectionsResult>(
			sectionKeys.list(userId)
		)

		if (sectionData) {
			setTimeout(() => {
				get().queryClient.setQueryData(
					sectionKeys.list(userId),
					produce(sectionData, (draft) => {
						draft.byId = get().userView.sections.byId
					})
				)
			})
		}
	}

// Mutation to be used inside of an immer produce function
export const moveTaskToSection = (
	sectionsById: SectionsById,
	nextSectionId: SectionId,
	nextTask: Task
) => {
	const prevSection = find(
		(section) => section.tasks.includes(nextTask.id),
		values(sectionsById)
	)
	if (prevSection) {
		sectionsById[prevSection.id] = removeTaskFromSection(
			prevSection,
			nextTask.id
		)
	}

	const nextSection = sectionsById[nextSectionId]
	if (nextSection) {
		sectionsById[nextSectionId] = addTaskToSection(nextSection, nextTask)
	}
}

const addTaskIdToList = (taskIds: string[], taskId: string) => {
	taskIds.push(taskId)
}
const removeTaskIdFromList = (taskIds: string[], taskId: string) => {
	const index = taskIds.indexOf(taskId)
	if (index >= 0) {
		taskIds.splice(index, 1)
	}
}

export const createUpdateTaskInFollowUp =
	(set: MutatedSetState) => (taskId: string, changes: Partial<Task>) => {
		set((draft) => {
			const playerId = draft.player.id
			const userId = draft.userView.user.id
			// Ignore tasks when we're not viewing the player tasks.
			if (!userId || !playerId || userId !== playerId) {
				return
			}

			if (has('ownerId', changes)) {
				if (changes.ownerId === playerId) {
					// TODO: only add if the task also has an assigneeId, else
					// no follow up is needed on the task
					//addTaskIdToList(draft.userView.followUp.taskIds, taskId)
				} else {
					removeTaskIdFromList(
						draft.userView.followUp.taskIds,
						taskId
					)
				}
			}

			if (has('assigneeId', changes)) {
				if (changes.assigneeId === userId) {
					removeTaskIdFromList(
						draft.userView.followUp.taskIds,
						taskId
					)
				} else {
					addTaskIdToList(draft.userView.followUp.taskIds, taskId)
				}
			}
		})
	}

export const createUpdateTaskInSections =
	(set: MutatedSetState) => (taskId: string, changes: Partial<Task>) => {
		set(async (draft) => {
			const playerId = draft.player.id
			const userId = draft.userView.user.id
			if (!userId || !playerId) {
				return
			}

			const getNextTask = async (
				draft: WritableDraft<AppState>,
				prevTask: Task,
				changes: Partial<Task>
			) => {
				if (prevTask) {
					return Promise.resolve(
						mergeDeepLeft(changes, prevTask) as Task
					)
				} else {
					// If there is an assigneeId change and prevTask doesn't exist, then
					// lazy load the task so we can add it to the sections.
					if (
						changes.assigneeId != null &&
						changes.assigneeId === userId
					) {
						// Updating the assigneeId normally just works fine. But
						// adding a task and then updating the assigneeId shortly after
						// will get to this point where the task is not in memory and
						// we have to lazy load it. staleTime has to be set to 0 for
						// this to work, else the query gets skipped if we have a
						// cached version.
						return draft.queryClient.fetchQuery(
							taskKeys.detail(taskId),
							fetchTask(
								draft.apiAdapter,
								draft.queryClient as QueryClient,
								taskId
							),
							{ staleTime: 0 }
						)
					}
				}

				return null
			}

			const prevTask = draft.queryClient.getQueryData<Task>(
				taskKeys.detail(taskId)
			) as Task

			try {
				const nextTask = await getNextTask(draft, prevTask, changes)

				if (nextTask) {
					const sectionsById = draft.userView.sections.byId
					const prevSection = find(
						(section) => section.tasks.includes(nextTask.id),
						values(sectionsById)
					)
					const prevSectionId = prevSection?.id
					const nextSectionId = getSectionId(
						nextTask,
						userId,
						playerId
					)

					if (prevSectionId !== nextSectionId) {
						moveTaskToSection(sectionsById, nextSectionId, nextTask)
					}
				}
			} catch (err) {
				if (!isCancelledError(err)) {
					throw new TraceableError(
						'Failed to update task in sections',
						err
					)
				}
			}
		})

		rebuildSections(set)
	}

export const rebuildSections = (set: MutatedSetState) => {
	set((draft) => {
		const playerId = draft.player.id
		const userId = draft.userView.user.id

		const originalDraft = original(draft)
		const queryClient = (
			originalDraft ? originalDraft.queryClient : draft.queryClient
		) as QueryClient

		const sectionData = userId
			? queryClient.getQueryData<SectionsResult>(sectionKeys.list(userId))
			: null

		if (!playerId) {
			draft.errors.next({
				error: new Error(
					`Expected playerId to be truthy, got: '${playerId}'`
				),
				message: 'An error has occured. Auth ID is missing.',
			})
			return
		}

		if (!sectionData || !userId) {
			return
		}

		const activeItems = draft.userView.activeTasks.taskIds
		const completedItems = draft.userView.completedTasks.taskIds

		const combined = {
			items: [...activeItems, ...completedItems],
			privateTaskCount: draft.userView.activeTasks.privateTaskCount || 0,
		}

		const selectedFilters = draft.userView.filter.selected
		const criteria = new AndCriteria(selectedFilters)

		const tasks = mapExistingTasks(queryClient, combined.items)

		const filteredTasks = pipe(
			tasks,
			filter((task) => task.id !== draft.player.inboxTaskId),
			filter((task) => criteria.check(task))
		)

		const { byId, displayOrder, taskCount } = buildSectionData(
			sectionData,
			filteredTasks,
			playerId,
			userId
		)

		draft.userView.sections.displayOrder = displayOrder
		// TODO: check if immer handles immutability correctly like this...
		// Else merge each one manually so only one section gets rendered at
		// a time.
		mutableMerge(
			draft.userView.sections.byId,
			byId,
			(objValue, srcValue) => {
				if (Array.isArray(objValue)) {
					if (equals(objValue, srcValue)) {
						return objValue
					} else {
						return srcValue
					}
				}
			}
		)
		draft.userView.sections.privateTaskCount = combined.privateTaskCount
		draft.userView.sections.taskCount = taskCount
	})
}
