export const ADD_USER = 'ADD_USER'
export const FETCH_RECENT_ASSIGNEES_FAILURE = 'FETCH_RECENT_ASSIGNEES_FAILURE'
export const FETCH_RECENT_ASSIGNEES_REQUEST = 'FETCH_RECENT_ASSIGNEES_REQUEST'
export const FETCH_RECENT_ASSIGNEES_SUCCESS = 'FETCH_RECENT_ASSIGNEES_SUCCESS'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USER_OPTIONS = 'FETCH_USER_OPTIONS'
export const FETCH_USER_PRODUCTIVITY = 'FETCH_USER_PRODUCTIVITY'
export const FETCH_USER_STATS = 'FETCH_USER_STATS'
export const FETCH_USER_WORKLOAD = 'FETCH_USER_WORKLOAD'
export const LAZY_LOAD_USER = 'LAZY_LOAD_USER'
export const MERGE_USER = 'MERGE_USER'
export const RECEIVE_USER_OPTIONS = 'RECEIVE_USER_OPTIONS'
export const SET_ADMIN_FAILURE = 'SET_ADMIN_FAILURE'
export const SET_ADMIN_REQUEST = 'SET_ADMIN_REQUEST'
export const SET_ADMIN_SUCCESS = 'SET_ADMIN_SUCCESS'
export const SET_IS_ONLINE = 'SET_IS_ONLINE'
export const SET_USER = 'SET_USER'
export const SET_USER_OPTIONS = 'SET_USER_OPTIONS'
export const SET_USER_PRODUCTIVITY = 'SET_USER_PRODUCTIVITY'
export const SET_USER_STATS = 'SET_USER_STATS'
export const SET_USER_WORKLOAD = 'SET_USER_WORKLOAD'
export const SOCKET_USER_FOCUS = 'SOCKET_USER_FOCUS'
export const USER_IS_OFFLINE = 'USER_IS_OFFLINE'
export const USER_IS_ONLINE = 'USER_IS_ONLINE'
export const UPDATE_USER = 'UPDATE_USER'

export const addUser = (payload) => ({ type: ADD_USER, payload })

export const fetchRecentAssigneesFailure = (payload) => ({
	type: FETCH_RECENT_ASSIGNEES_FAILURE,
	payload,
})
export const fetchRecentAssigneesRequest = (payload) => ({
	type: FETCH_RECENT_ASSIGNEES_REQUEST,
	payload,
})
export const fetchRecentAssigneesSuccess = (payload) => ({
	type: FETCH_RECENT_ASSIGNEES_SUCCESS,
	payload,
})
export const fetchUser = (userId) => ({ type: FETCH_USER, userId })
export const fetchUsersFailure = (reason) => ({
	type: FETCH_USERS_FAILURE,
	reason,
})
export const fetchUsersRequest = (payload) => ({
	type: FETCH_USERS_REQUEST,
	payload,
})
export const fetchUsersSuccess = (response) => ({
	type: FETCH_USERS_SUCCESS,
	response,
})
export const fetchUserOptions = (userId, optionIds = ['tasksTableSort']) => ({
	type: FETCH_USER_OPTIONS,
	payload: { userId, optionIds },
})
export const fetchUserProductivity = (userId) => ({
	type: FETCH_USER_PRODUCTIVITY,
	payload: userId,
})
export const fetchUserStats = (userId) => ({
	type: FETCH_USER_STATS,
	payload: userId,
})
export const fetchUserWorkload = (userId) => ({
	type: FETCH_USER_WORKLOAD,
	payload: userId,
})

export const mergeUser = (user) => ({ type: MERGE_USER, payload: user })

export const setAdminFailure = (reason) => ({ type: SET_ADMIN_FAILURE, reason })
export const setAdminRequest = (userId, isAdmin) => ({
	type: SET_ADMIN_REQUEST,
	userId,
	isAdmin,
})
export const setAdminSuccess = () => ({ type: SET_ADMIN_SUCCESS })
export const setIsOnline = (ids) => ({ type: SET_IS_ONLINE, payload: ids })
export const setUser = (user) => ({ type: SET_USER, user })
export const setUserOptions = (userId, options) => ({
	type: SET_USER_OPTIONS,
	payload: { userId, options },
})

export const receiveUserOptions = (userId, options) => ({
	type: RECEIVE_USER_OPTIONS,
	payload: { userId, options },
})

export const setUserProductivity = (userId, productivity) => ({
	type: SET_USER_PRODUCTIVITY,
	payload: { userId, productivity },
})
export const setUserStats = (userId, stats) => ({
	type: SET_USER_STATS,
	payload: { userId, stats },
})
export const setUserWorkload = (userId, workload) => ({
	type: SET_USER_WORKLOAD,
	payload: { userId, workload },
})

export const socketUserFocus = (payload) => ({
	type: SOCKET_USER_FOCUS,
	payload,
})
export const socketOptions = (payload) =>
	receiveUserOptions(payload.userId, payload.options)

export const userIsOffline = (id) => ({ type: USER_IS_OFFLINE, id })
export const userIsOnline = (id) => ({ type: USER_IS_ONLINE, id })

export const updateUser = (user) => ({ type: UPDATE_USER, payload: user })
