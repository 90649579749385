import { Task } from '@tyto/types'

import { TaskActivity, TaskActivityWorkflow } from './task-activity-schemas'

export interface TaskActivityTaskMeta {
	id: string
	title: string
}
export interface TaskActivityUserMeta {
	id: string
	name: string
	nickname: string
	gravatar: string
}
export interface TaskActivityWorkflowMeta {
	id: string
	title: string
}

export interface DataSource {
	readonly task: Record<string, TaskActivityTaskMeta>
	readonly user: Record<string, TaskActivityUserMeta>
	readonly workflow: Record<string, TaskActivityWorkflowMeta>
}

/**
 * Creates and adds a task activity based off of task changes
 */
export const addInferredTaskActivity = (
	playerId: string,
	oldTask: Task,
	newTask: Task
) => {}

export const buildMetaForTaskActivity = (
	activity: TaskActivity,
	dataSource: {
		task: Record<string, TaskActivityTaskMeta>
		user: Record<string, TaskActivityUserMeta>
		workflow: Record<string, TaskActivityWorkflowMeta>
	} = { task: {}, user: {}, workflow: {} }
) => {
	const result: TaskActivity['meta'] = {}
	const getTask = (taskId: string) => dataSource.task[taskId]
	const getUser = (userId: string) => dataSource.user[userId]
	const getWorkflow = (workflowId: string) => dataSource.workflow[workflowId]

	Object.keys(activity).forEach((key) => {
		const value = activity[key]
		if (!value) {
			return
		}
		switch (key) {
			case 'oldParentId':
				result.oldTask = getTask(value)
				return
			case 'newParentId':
				result.newTask = getTask(value)
				return

			case 'assigneeId':
				result.assignee = getUser(value)
				return
			case 'followerId':
			case 'removeFollowerId':
				result.follower = getUser(value)
				return
			case 'ownerId':
				result.owner = getUser(value)
				return
			case 'userId':
				result.user = getUser(value)
				return
			case 'workflowId':
				result.workflow = getWorkflow(value)
				return
		}
	})

	return result
}

export const WORKFLOW_ACTIVITY_TYPE = {
	ADD: 'add',
	DONE: 'done',
	MOVE: 'move',
	REMOVE: 'remove',
	REQUIREMENTS_CHANGE: 'requirementsChange',
}

export const getWorkflowActivityType = (activity: TaskActivityWorkflow) => {
	// Step requirements were changed or marked as completed
	if (activity.hasRequirementsChanged) {
		return WORKFLOW_ACTIVITY_TYPE.REQUIREMENTS_CHANGE
	}

	// Step was changed
	if (
		activity.newStepId &&
		activity.oldStepId &&
		activity.newStepId !== activity.oldStepId
	) {
		return WORKFLOW_ACTIVITY_TYPE.MOVE
	}

	// Workflow step was completed
	if (
		activity.workflowId &&
		activity.completedSteps &&
		activity.completedSteps.length > 0
	) {
		return WORKFLOW_ACTIVITY_TYPE.DONE
	}

	// Workflow was removed from this task
	if (activity.removedWorkflowId) {
		return WORKFLOW_ACTIVITY_TYPE.REMOVE
	}

	// Workflow was added to this task
	if (activity.workflowId && !activity.oldStepId) {
		return WORKFLOW_ACTIVITY_TYPE.ADD
	}

	return { error: new Error('Unknown workflow state') }
}
