import produce from 'immer'
import { QueryClient } from 'react-query'

import { deepMerge } from '@tyto/utils'

import { User } from '../../types'
import { userKeys } from '../queries/users/userKeys'

const updateUserQueryCacheWithList = (
	queryClient: QueryClient,
	userList: User[]
) => {
	userList.forEach((user) => {
		if (!user) {
			return
		}

		// Add users to queryCache
		const queryKey = userKeys.detail(user.id)
		queryClient.setQueryData<User>(
			queryKey,
			produce((draft) => {
				return draft ? deepMerge(user, draft) : user
			})
		)
	})
}

export default updateUserQueryCacheWithList
