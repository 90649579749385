import * as Sentry from '@sentry/react'
import axios from 'axios'
import findKey from 'lodash/findKey'
import defaults from 'lodash/fp/defaults'
import isNumber from 'lodash/isNumber'
import join from 'lodash/join'
import keys from 'lodash/keys'
import map from 'lodash/map'
import omit from 'lodash/omit'
import size from 'lodash/size'
import moment from 'moment'

import { optionIds } from '@tyto/dna'
import { isStoreError, useStore } from '@tyto/dna/store'
import { createFileId } from '@tyto/helpers'
import { env } from '@tyto/utils'

const { NX_APP_ENV } = process.env

const removeId = (obj) => omit(obj, 'id')

export const apiInstance = axios.create({ baseURL: `${env('API_URL')}/v1` })

apiInstance.interceptors.request.use((request) => {
	const token = useStore.getState().player.authToken

	request.headers['Authorization'] = `Bearer ${token}`
	request.headers['X-Tyto-Source'] = 'web'
	// request.headers['X-Socket-Id'] = socket.id

	if (NX_APP_ENV) {
		request.headers['X-Tyto-Environment'] = NX_APP_ENV
	}
	return request
})

const endpointPatterns = {
	'/login': /\/login$/,
	'/tasks': /\/tasks$/,
	'/tasks/:taskId': /\/tasks\/[^/]+$/,
	'/users': /\/users$/,
	'/users/:userId': /\/users\/[^/]+$/,
}
export const catchApiResponseError = (logout) => (err) => {
	Sentry.withScope((scope) => {
		const error = isStoreError(err) ? err.error : err

		scope.setExtra('config', error.config)

		// Separate and get extra error data for Sentry.
		if (error.response) {
			// The server responded with a status code outside the range of 2xx.
			if (error.response.status === 401) {
				logout()
			}

			const endpoint = findKey(endpointPatterns, (regex, key) =>
				error.response.config.url.match(regex)
			)
			const queryParams = join(keys(error.response.config.params), ',')
			let url = endpoint
			if (queryParams) {
				url = `${endpoint}?${queryParams}`
			}

			let errorMessage = `${error.message}: ${error.response.data.message}`
			if (url) {
				errorMessage += ` (${url})`
			}
			error.message = errorMessage

			scope.setExtra('status', error.response.status)
			scope.setExtra('statusText', error.response.statusText)
			scope.setExtra('responseData', error.response.data)

			Sentry.captureException(error)
		} else if (error.request) {
			// The request was made but no response was received.
			// There is not much we can do about this error. Retry functionality
			// has been added to alleviate these errors. Disabled to reduce
			// noise in Sentry.
			/* const error = new Error(
				'A request was made but no response received'
			)
			scope.setExtra('request', error.request)
			Sentry.captureException(error) */
			console.debug(
				'A request was made but no response received. Possible network connectivity issues.'
			)
		} else if (axios.isCancel(error)) {
			// Axios request was aborted
			// Do nothing
		} else {
			// Something happened in setting up the request that triggered an Error.
			Sentry.captureException(error)
		}
	})

	return Promise.reject(err)
}

export const convertDate = (date) => moment(date).format()

const createTask = async (task, config) => {
	const { data } = await apiInstance.post('/tasks', task, config)
	return data
}

const patchUser = (user) => {
	// The organisationId might be a number. If so, type cast it to a string.
	if (user && isNumber(user.organisationId)) {
		return {
			...user,
			organisationId: String(user.organisationId),
		}
	}

	return user
}

const Api = {
	addBillingCard: (token) =>
		apiInstance
			.post('/billing/cards', token)
			.then((response) => response.data),

	addTask: createTask,

	addTaskComment: (taskId, comment, id, descrJson, replyTo) =>
		apiInstance
			.post(`/tasks/${taskId}/comments`, {
				descr: comment,
				descrJson,
				id,
				replyTo,
			})
			.then((response) => response.data),

	createTask,

	fetchOption: (userId, optionId) =>
		apiInstance
			.get(`/users/${userId}/options/${optionId}`)
			.then((response) => response.data),

	fetchBillingDetails: () =>
		apiInstance.get('/billing/details').then((response) => response.data),

	fetchCard: () =>
		apiInstance.get('/billing/cards').then((response) => response.data),

	fetchChatMessages: (roomId, lastMessageId, perPage = 30) =>
		apiInstance
			.get(
				`/chat/rooms/${roomId}/messages?${
					lastMessageId ? `lastMessageId=${lastMessageId}&` : ''
				}perPage=${perPage}`
			)
			.then((response) => response.data),
	createMessage: (roomId, message) =>
		apiInstance.post(`/chat/rooms/${roomId}/messages`, message),
	updateRoom: (room) =>
		apiInstance
			.put(`/chat/rooms/${room.id}`, room)
			.then((response) => response.data),
	notifyChatNow: (roomId) =>
		apiInstance
			.post(`/chat/rooms/${roomId}/notify`)
			.then((response) => response.data),
	typingInChat: (roomId) =>
		apiInstance
			.post(`/chat/rooms/${roomId}/typing`)
			.then((response) => response.data),

	fetchNotifications: (params) =>
		apiInstance
			.get('/users/me/notification', { params })
			.then((response) => response.data),

	fetchPlayer: (config) => Api.fetchUser('me', config),

	fetchProjects: (params = {}, config) =>
		apiInstance
			.get(
				'/tasks',
				{
					params: {
						...params,
						isProject: true,
						pageSize: params.pageSize || 0,
					},
				},
				config
			)
			.then((response) => response.data),

	fetchProjectChildren: (parentId, { page = 1, pageSize = 0 } = {}) =>
		apiInstance
			.get('/tasks', {
				params: {
					parentId: parentId || 'null',
					pageSize,
					page,
				},
			})
			.then((response) => response.data),

	// @deprecated use apiAdapter.player.getRecentlyViewedProjects()
	fetchRecentAssignees: (limit = 5) =>
		apiInstance
			.get(
				`/users/me/recents/${optionIds.getRecentUsersId('assignee')}`,
				{ params: { limit } }
			)
			.then((response) => response.data),

	fetchRecentProjects: (userId, limit = 5) =>
		apiInstance
			.get(`/users/me/recents/${optionIds.getRecentProjectsId(userId)}`, {
				params: { limit },
			})
			.then((response) => response.data),

	fetchSettings: () =>
		apiInstance
			.get('/users/me/settings')
			.then((response) => response.data)
			.then((response) => {
				const newResponse = {}
				Object.keys(response).forEach((key) => {
					const value = response[key]
					const booleanFields = [
						'syncFromGoogleCalendar',
						'syncToGoogleCalendar',
					]
					// Convert numbers from the api into booleans.
					if (booleanFields.includes(key)) {
						newResponse[key] = !!value
					} else {
						newResponse[key] = value
					}
				})
				return newResponse
			}),

	fetchTask: (taskId, config) =>
		taskId
			? apiInstance
					.get(`/tasks/${taskId}`, config)
					.then((response) => response.data)
			: Promise.reject(
					new Error(
						`Api.fetchTask: a taskId is required, '${taskId}' given`
					)
				),

	fetchTaskActivity: (taskId, params = { page: 1, pageSize: 10 }, config) =>
		taskId
			? apiInstance
					.get(`/tasks/${taskId}/activity`, { params, ...config })
					.then((response) => response.data)
			: null,

	// @deprecated use apiAdapter.tasks.getList()
	fetchTaskList: (params, config) =>
		apiInstance
			.get('/tasks', { params, ...config })
			.then((response) => response.data),

	fetchTaskLog: (taskLogId, axiosOptions) =>
		apiInstance
			.get(`/taskLogs/${taskLogId}`, axiosOptions)
			.then((response) => response.data),

	fetchTaskStats: (taskId) =>
		apiInstance
			.get(`/tasks/${taskId}/stats`)
			.then((response) => response.data)
			.then((data) =>
				defaults({
					percentComplete: 0,
					totalHoursAllocated: 0,
					totalHoursTaken: 0,
					tasksDone: 0,
					tasksTotal: 0,
				})(data)
			)
			.then((data) => ({
				hours: Math.round(
					data.totalHoursAllocated - data.totalHoursTaken
				),
				percentComplete: Math.floor(data.percentComplete),
				tasksLeft: data.tasksTotal - data.tasksDone,
			})),

	fetchTeam: () =>
		apiInstance
			.get('/users')
			.then((response) => response.data)
			.then(
				(response) => response.items,
				() => []
			)
			.then(map(patchUser)),

	fetchUsers: (params, config) =>
		apiInstance
			.get('/users', { params, ...config })
			.then((response) => response.data)
			.then(
				(response) => (response ? response.items : []),
				() => []
			)
			.then(map(patchUser)),

	fetchUser: (userId, config) =>
		userId
			? apiInstance
					.get(`/users/${userId}`, config)
					.then((response) => response.data)
					.then(patchUser)
			: Promise.reject(
					new Error(
						`Api.fetchUser: a userId is required, '${userId}' given`
					)
				),

	fetchUserProductivity: (userId, config) =>
		apiInstance
			.get(`/users/${userId}/productivity`, config)
			.then((response) => response.data),

	fetchUserStats: (userId, config) =>
		apiInstance
			.get(`/users/${userId}/stats`, config)
			.then((response) => response.data),

	fetchUserWorkload: (userId, config) =>
		apiInstance
			.get(`/users/${userId}/workload`, config)
			.then((response) => response.data),

	login: (email, password) =>
		apiInstance
			.post('/login', { email, password })
			.then((response) => response.data),

	mergePlayers: (token) =>
		apiInstance.post(`/users/merge`, { resetToken: token }),

	moveTask: (taskId, parentId, position) =>
		apiInstance
			.post(`/tasks/${taskId}/move`, { parentId, position })
			.then((response) => response.data),

	removeBillingCard: (cardId) =>
		apiInstance
			.delete(`/billing/cards/${cardId}`)
			.then((response) => response.data),

	removeFollower: (taskId, followerId) =>
		apiInstance
			.delete(`/tasks/${taskId}/followers/${followerId}`)
			.then((response) => response.data),

	// @deprecated use apiAdapter.tasks.getList()
	searchProjects: ({ pageSize = 30, page = 1, query }, config) =>
		apiInstance
			.get('/tasks', {
				params: {
					search: query,
					statusCodes: 'active',
					pageSize,
					page,
				},
				...config,
			})
			.then((response) => response.data),

	setActive: (email) =>
		apiInstance
			.post('/users/setActive', { email })
			.then((response) => response.data),

	setFocus: (focus) =>
		apiInstance
			.post(`/users/focus`, focus)
			.then((response) => response.data),

	setLastReadNotification: (control) =>
		apiInstance
			.post('/users/me/notification', { control })
			.then((response) => response.data),

	setTaskAsDone: (taskId, comment, draftModel) => {
		if (!taskId) {
			return Promise.reject(new Error('setTaskAsDone requires a taskId'))
		}

		let body = {}

		if (comment) {
			body = { comment, draftModel }
		}

		return apiInstance
			.post(`/tasks/${taskId}/setDone`, body)
			.then((response) => response.data)
	},

	switchTeam: (email, taskId, inviteType) =>
		apiInstance
			.post('/users/switchTeam', { email, inviteType, taskId })
			.then((response) => response.data),

	syncGoogleCalander: (code) =>
		apiInstance
			.post('/google/calendar/sync', { googleOneTimeCode: code })
			.then((response) => response.data),

	updateAdmin: (userId, isAdmin) =>
		apiInstance
			.put(`/users/${userId}/toggle-admin`, { isAdmin })
			.then((response) => response.data),

	updateBillingDetails: (changes) =>
		apiInstance
			.put('/billing/details', changes)
			.then((response) => response.data),

	updateLastReadDate: (roomId, lastReadDate) =>
		apiInstance
			.put(`/chat/rooms/${roomId}/lastReadDate`, { lastReadDate })
			.then((response) => response.data),
	archiveRoom: (roomId, archive = true) =>
		apiInstance
			.put(`/chat/rooms/${roomId}/archive`, { archive })
			.then((response) => response.data),

	updateSettings: (payload) =>
		apiInstance
			.put('/users/me/settings', payload)
			.then((response) => response.data),

	updateTask: (task) =>
		task.id
			? apiInstance
					.put(`/tasks/${task.id}`, removeId(task))
					.then((response) => response.data)
			: Promise.reject('No task id'),

	updateMultipleTasks: (taskIds, updateData) =>
		apiInstance
			.patch(`/tasks/${taskIds.join(',')}`, removeId(updateData))
			.then((response) => response.data),

	setDoneMultipleTasks: (taskIds) =>
		apiInstance
			.patch(`/tasks/${taskIds.join(',')}/setDone`)
			.then((response) => response.data),

	updatePlan: ({ planId }) =>
		apiInstance
			.put('/billing/plan', { id: planId })
			.then((response) => response.data),
	cancelSubscription: () =>
		apiInstance.delete('/billing/plan').then((response) => response.data),

	updatePlayer: (changes) =>
		apiInstance.put('/users/me', changes).then((response) => response.data),

	updateTimer: (taskId, currentTimer) =>
		apiInstance
			.post(`/tasks/${taskId}/timers`, currentTimer)
			.then((response) => response.data),

	updateUser: (userId, changes) =>
		apiInstance
			.put(`/users/${userId}`, changes)
			.then((response) => response.data),
	fetchEmailSettings: (token) =>
		apiInstance
			.get(`/email-settings?token=${token}`)
			.then((response) => response.data),

	setEmailSettings: (token, settings) =>
		apiInstance
			.post(`/email-settings?token=${token}`, settings)
			.then((response) => response.data),

	fetchPageMeta: (url) =>
		apiInstance
			.get(`page-meta?url=${url}`)
			.then((response) => response.data),

	fetchWorkflows: () =>
		apiInstance.get('/workflows').then((response) => response.data),
	updateWorkflow: (id, workflow) =>
		apiInstance
			.put(`/workflows/${id}`, workflow)
			.then((response) => response.data),
	createWorkflow: (workflow) =>
		apiInstance
			.post('/workflows', workflow)
			.then((response) => response.data),
	deleteWorkflow: (id) =>
		apiInstance
			.delete(`/workflows/${id}`)
			.then((response) => response.data),
	getInviteToken: (type, taskId, isGuest, notificationLevel) =>
		apiInstance
			.get(
				`tokens?type=${type}&taskId=${taskId}&isGuest=${isGuest}&notificationLevel=${notificationLevel}`
			)
			.then((response) => response.data),

	// Organisation handlers
	fetchOrganisation: (config) =>
		apiInstance
			.get(`/users/me/organisation`, config)
			.then((response) => response.data),

	// Reaction handlers
	addReaction: (taskId, activityId, reactionType) =>
		apiInstance
			.put(
				`/tasks/${taskId}/activity/${activityId}/reactions/${encodeURIComponent(
					reactionType
				)}`
			)
			.then((response) => response.data),
	removeReaction: (taskId, activityId, reactionType) =>
		apiInstance
			.delete(
				`/tasks/${taskId}/activity/${activityId}/reactions/${encodeURIComponent(
					reactionType
				)}`
			)
			.then((response) => response.data),

	// Section handlers
	fetchSections: (userId, config) =>
		apiInstance
			.get(`/users/${userId}/sections`, config)
			.then((response) => response.data),

	updateSection: (userId, section, config) =>
		apiInstance
			.put(
				`/users/${userId}/sections/${section.id}`,
				{
					manualSortOrder: section.manualSortOrder,
					sortType: section.sortType,
				},
				config
			)
			.then((response) => response.data),

	// Task time handlers
	getTaskTime: (taskId, taskTimeId) =>
		apiInstance
			.get(`/tasks/${taskId}/times/${taskTimeId}`)
			.then((response) => response.data),
	updateTaskTime: (taskId, taskTimeId, body) =>
		apiInstance
			.put(`/tasks/${taskId}/times/${taskTimeId}`, body)
			.then((response) => response.data),
	removeTaskTime: (taskId, taskTimeId) =>
		apiInstance
			.delete(`/tasks/${taskId}/times/${taskTimeId}`)
			.then((response) => response.data),

	// WorkflowData handlers
	changeWorkflowStep: (taskIds, workflow, step) =>
		apiInstance
			.patch(`/tasks/${taskIds.join(',')}/changeWorkflowStep`, {
				step,
				workflow,
			})
			.then((response) => response.data),
}

export default Api
