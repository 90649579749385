import { StrictMode } from 'react'
import { enableMapSet } from 'immer'
import { createRoot } from 'react-dom/client'
import { QueryClientProvider } from 'react-query'

import { useStore } from '@tyto/dna/store'
import { env } from '@tyto/utils'

import { configureApiAdapter } from './configure-api-adapter'
import { configureQueryClient } from './configure-query-client'
import Root from './Root'
import { configureSentry } from './sentry'

// Enable Immer's Map and Set support. First required for Set support in chat
// message reactions.
enableMapSet()

configureSentry()
configureApiAdapter()
configureQueryClient()

// Add dev only console logging
// @ts-expect-error property dev does not exist on console
console.dev = function (...args) {
	if (env('NODE_ENV') !== 'production') {
		console.debug.apply(this, args)
	}
}

const container = document.getElementById('root')

if (!container) {
	throw new Error('No root element found')
}

// Loading strategy:
// - Start with quote switcher (no react)
// - Hydrate into quote switcher (with react) if possible
// - Check if logged in
// - If logged in, load authed app
// - else, load unauthed app
const root = createRoot(container)
root.render(
	<StrictMode>
		<QueryClientProvider client={useStore.getState().queryClient}>
			<Root />
		</QueryClientProvider>
	</StrictMode>
)
