import * as Sentry from '@sentry/react'
import forEach from 'lodash/fp/forEach'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { useStore } from '@tyto/dna/store'

import {
	addUser,
	FETCH_RECENT_ASSIGNEES_REQUEST,
	FETCH_USER,
	FETCH_USER_PRODUCTIVITY,
	FETCH_USER_STATS,
	FETCH_USER_WORKLOAD,
	FETCH_USERS_REQUEST,
	fetchRecentAssigneesFailure,
	fetchRecentAssigneesSuccess,
	fetchUsersFailure,
	fetchUsersSuccess,
	mergeUser,
	SET_ADMIN_REQUEST,
	setAdminFailure,
	setAdminSuccess,
	setUserProductivity,
	setUserStats,
	setUserWorkload,
	UPDATE_USER,
} from '../actions'
import Api from '../api'
import { getUser2 as getUser } from '../reducers/users-reducer'
import { handleError } from '../sentry'

export function* usersSaga() {
	yield takeLatest(FETCH_RECENT_ASSIGNEES_REQUEST, fetchRecentAssignees)
	yield takeLatest(FETCH_USER, fetchUser)
	yield takeLatest(FETCH_USERS_REQUEST, fetchUsers)
	yield takeLatest(SET_ADMIN_REQUEST, setAdmin)
	yield takeEvery(FETCH_USER_PRODUCTIVITY, handleFetchUserProductivity)
	yield takeEvery(FETCH_USER_STATS, handleFetchUserStats)
	yield takeEvery(FETCH_USER_WORKLOAD, handleFetchUserWorkload)
	yield takeEvery(UPDATE_USER, handleUpdateUser)
}

function* fetchRecentAssignees() {
	try {
		const userIds = yield call(Api.fetchRecentAssignees)
		yield put(
			fetchRecentAssigneesSuccess((userIds || []).filter((id) => !!id))
		)
	} catch (err) {
		handleError(err)
		yield put(fetchRecentAssigneesFailure(err.message))
	}
}

function* fetchUser({ userId }) {
	if (!userId || userId === 'undefined') {
		Sentry.withScope((scope) => {
			scope.setExtra('userId', `${userId} type:${typeof userId}`)
			Sentry.captureException(new Error('Not a valid user id'))
		})
		return
	}

	const state = yield select()
	try {
		const user = yield call(Api.fetchUser, userId)
		if (getUser(state, { userId })) {
			yield put(mergeUser(user))
		} else {
			yield put(addUser(user))
		}
	} catch (err) {
		Sentry.withScope((scope) => {
			scope.setExtra('userId', userId)
			scope.setExtra('request', 'GET /users/:userId')
			scope.setExtra('err', err)
			handleError(err)
		})
	}
}

function* fetchUsers({ payload = {} }) {
	const { isActive = true, isOffline } = payload
	try {
		const users = yield call(Api.fetchUsers, { isActive, isOffline })
		forEach((user) => {
			if (!user.id) return
			useStore
				.getState()
				.queryClient.setQueryData(['users', user.id], user, {
					staleTime: 1000 * 60 * 60 * 24,
				})
		})(users)
		yield put(fetchUsersSuccess(users))
	} catch (err) {
		yield put(fetchUsersFailure(err.message))
		Sentry.withScope((scope) => {
			scope.setExtra('url', '/users')
			handleError(err)
		})
	}
}

function* handleFetchUserProductivity({ payload: userId }) {
	try {
		const productivity = yield call(Api.fetchUserProductivity, userId)
		yield put(setUserProductivity(userId, productivity))
	} catch (err) {
		Sentry.withScope((scope) => {
			scope.setExtra('url', `/users/${userId}/productivity`)
			handleError(err)
		})
	}
}

function* handleFetchUserStats({ payload: userId }) {
	try {
		const stats = yield call(Api.fetchUserStats, userId)
		yield put(setUserStats(userId, stats))
	} catch (err) {
		Sentry.withScope((scope) => {
			scope.setExtra('url', `/users/${userId}/stats`)
			handleError(err)
		})
	}
}
function* handleFetchUserWorkload({ payload: userId }) {
	try {
		const workload = yield call(Api.fetchUserWorkload, userId)
		yield put(setUserWorkload(userId, workload))
	} catch (err) {
		Sentry.withScope((scope) => {
			scope.setExtra('url', `/users/${userId}/workload`)
			handleError(err)
		})
	}
}

function* setAdmin({ userId, isAdmin }) {
	yield put(mergeUser({ id: userId, isAdmin }))
	try {
		yield call(Api.updateAdmin, userId, !!isAdmin)
		yield put(setAdminSuccess())
	} catch (err) {
		Sentry.withScope((scope) => {
			scope.setExtra('userId', userId)
			Sentry.captureException(err)
		})
		yield put(mergeUser({ id: userId, isAdmin: !isAdmin }))
		yield put(setAdminFailure(err.message))
	}
}

function* handleUpdateUser({ payload }) {
	try {
		yield call(Api.updateUser, payload.id, payload)
		yield put(setAdminSuccess())
	} catch (err) {
		Sentry.withScope((scope) => {
			scope.setExtra('payload', payload)
			handleError(err)
		})
	}
}
